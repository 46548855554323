<template>
  <div>
    <div class="navigation">
      <!--      <div class="left">-->
      <!--        <img class="logo" src="../../../static/image/logo/logo(1).jpg" alt="" @click="logoClick('/')">-->
      <!--        <img src="../../../static/image/logo/logo.jpg" alt="">-->
      <!--        <div>-->
      <!--          <p>logo</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="right">
        <!--        导航栏-->
        <div class="navigationBar">
          <ul @mouseleave="mouseleave">
            <!--            <li @mouseenter="enter(1)" @click="routerPush('/industryInformation/EnterpriseDynamics', 1)">-->
            <!--              <p :class="this.index == 1 ? 'p1' : ''">企业动态</p>-->
            <!--            </li>-->
            <!--            <li @mouseenter="enter(2)" @click="routerPush('/industryInformation/latestInformation', 2)">-->
            <!--              <p :class="this.index == 2 ? 'p1' : ''">最新资讯</p>-->
            <!--            </li>-->
            <!--            <li @mouseenter="enter(3)" @click="routerPush('/industryInformation/designFrontier', 3)">-->
            <!--              <p :class="this.index == 3 ? 'p1' : ''">设计前沿</p>-->
            <!--            </li>-->
            <!--            newList ==  {{ newList }}-->
            <li v-for="(item,indexNew) in newTypyeList" :key="item.id" @click="routerPush('/informa/es', item.id)"
               :class="localId === item.id ? 'p1' : ''">
              <!--              <p :class="this.index == indexNew + 1 ? 'p1' : ''">{{ item.name }}</p>-->
              {{ item.name }}
            </li>

<!--            <li v-for="(item,indexNew) in newTypyeList" :key="item.id" @click="routerPush('/informa/es', item.id)"-->
<!--                @mouseenter="enter(item.id)" :class="index === item.id ? 'p1' : ''">-->
<!--              &lt;!&ndash;              <p :class="this.index == indexNew + 1 ? 'p1' : ''">{{ item.name }}</p>&ndash;&gt;-->
<!--              {{ item.name }}-->
<!--            </li>-->
          </ul>
          <div ref="border" style="margin-left: 14px;" :class="classId !== 0 ? 'border' : 'borderClass'"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {newsNewsTypeList} from "../../../api/industryInformation.js";

export default {
   name: "index",
  data() {
    return {
      id: 1,
      classId: 1,
      // list: 26,
      list: 14,
      index: 1,
      newTypyeList: [],
      newList: [],
      localId: null,
      routeList: [
        {
          path: '/informa/es',
          index: 1
        }, {
          path: '/informa/laton',
          index: 2
        }, {
          path: '/informa/der',
          index: 3
        }
      ]
    }
  },
  mounted() {
    if(this.localId != null){
      this.enter(this.localId)
    }
  },
  beforeCreate() {
  },
  watch:{
    $route(to,from){
      this.localId = localStorage.getItem('typeId')
    }
  },
  created() {
    // this.localId = localStorage.getItem('typeId')
    this.localId = 1;
    newsNewsTypeList().then(res => {
      this.newTypyeList = res
    })

    // this.$refs.border.style.cssText = "margin-left:" + this.list + 'px'
    // this.routeList.forEach(res => {
    //   if (res.path == this.$route.path) {
    //     this.enter(res.index)
    //     this.index = res.index
    //   }
    // })
  },
  methods: {
    // 导航栏跳转
    routerPush(name, id) {
      this.$router.push({name: name, query: {id: id}})
      this.enter(id)
      this.index = id
    },
    // 点击logo跳转首页
    logoClick(path) {
      this.$router.push({path: path})
    },
    enter(index) {
      if (index > this.id) {
        this.list += (index - this.id) * 90
        this.$refs.border.style.cssText = "margin-left:" + this.list + 'px'
      }
      if (index < this.id) {
        this.list -= (this.id - index) * 90
        this.$refs.border.style.cssText = "margin-left:" + this.list + 'px'
      }
      this.id = index
    },
    mouseleave() {
      // this.enter(this.index)   暂时无用
    }
  }
}
</script>

<style lang="less" scoped>
.navigation {
  margin-left: 11px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  //border-bottom: 1px solid rgba(61,61,61,.15);
  .left {
    display: flex;
    align-items: center;

    .logo {
      margin: 0;
      width: 169px;
      height: 50px;
      margin-top: -13px;
    }

    .logo:hover {
      cursor: pointer;
    }

    div {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      p {
        //text-align: center;
        font-size: 25px;
        color: #000000;
        cursor: pointer;
        font-weight: 600;
      }
    }

    img {
      margin-left: 10px;
    }
  }
  .p1 {
    color: #4B89DA;
  }
  .right {
    display: flex;
    align-items: center;

    .navigationBar {
      height: 100%;

      ul {
        display: flex;
        height: 100%;

        li {
          height: 100%;
          width: 90px;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            text-align: center;
            font-size: 16px;
            color: rgba(102, 102, 102, 1);
            cursor: pointer;
          }


        }

        li:hover {
          cursor: pointer;
        }

        li:hover p {
          color: #7384AB;
        }
      }

      .border {
        width: 63px;
        border-bottom: 2px solid #4B89DA;
        transition: all .5s;
        //margin-left: 26px;
      }

      .borderClass {
        display: none;
      }
    }
  }
}
</style>
