<template>
  <div>
    <!--    logo和导航栏-->
    <div :class="show ? 'navTitle' : 'navTitle1'">
      <navigation />
    </div>
    <div>
      <imageCom />
    </div>
    <div class="tab">
      <industryNavigation></industryNavigation>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
    <!--    页脚-->
    <div>
      <footers />
    </div>
  </div>
</template>

<script>
import navigation from '@/components/home/navigation/index';
import footers from '@/components/home/footers/index';
import industryNavigation from "../../components/home/navigation/industryNavigation";
import { foregroundNavigationDetails } from '../../api/homeNav'
import imageCom from "./imageCom";
export default {
  components: {
    navigation,
    footers,
    industryNavigation,
    imageCom,
  },
  data(){
    return{
      picId:null,
      dataObj:{},
      show:false
    }
  },
  watch:{
    //有些情况不触发--》
    $route(to,from){
      this.picId = this.$route.query.picId
    }
  },
  created() {
    this.picId = this.$route.query.picId
    if(this.picId != null) {
      localStorage.setItem('picId', this.picId)
    }
    let localId = localStorage.getItem('picId')
    if(localId != null){
      foregroundNavigationDetails(localId).then(res => {
        this.dataObj = res
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 100) {
        this.show = true
      } else {
        this.show = false
      }
    })
    // let oldTop = 0; //旧数据，初始为0
    // // 将自定义方法绑定到窗口滚动条事件
    // window.onscroll = () => {
    //   let top = document.scrollingElement.scrollTop; //触发滚动条，记录数值
    //   //旧数据大于当前位置，表示滚动条top向上滚动
    //   if (oldTop > top) {
    //     this.show = false;
    //     console.log("↑");
    //     // top: -18%;
    //   } else {
    //     //相反...
    //     this.show = true;
    //     console.log("↓");
    //   }
    //   oldTop = top;//更新旧的位置
    // };
  },
  name: "index"
}
</script>

<style scoped lang="less">
.tab{
  margin: 0 auto;
  width: 1200px;
}
.content{
  margin: 0 auto;
  width: 1200px;
}

</style>
